<mip-icon
  title="Chosen One"
  class="__badge-icon"
  pngIcon="chosen-one"
  [size]="badgeSize"
  *ngIf="user && user.isChosenOne"
  matTooltip="ChosenOne"
></mip-icon>

<mip-icon
  title="Chosen Plus"
  class="__badge-icon"
  pngIcon="chosen-plus"
  [size]="badgeSize"
  *ngIf="user && user.isChosenPlus"
  matTooltip="ChosenPlus"
></mip-icon>

<mip-icon
  title="Ambassador"
  class="__badge-icon"
  pngIcon="ambassador"
  [size]="badgeSize"
  *ngIf="user && user.isAmbassador"
  matTooltip="Ambassador"
></mip-icon>

<mip-icon
  title="Identity Verified"
  class="__badge-icon"
  pngIcon="verified"
  [size]="badgeSize"
  *ngIf="user && user.hasIdentityVerified"
  matTooltip="Verified"
></mip-icon>

<!--<mip-icon
  #iconCheckinLocal="matTooltip"
  *ngIf="user && user.hasCheckedInEvent('GOTCHOSEN_EXPERIENCE', true)"
  class="__badge-icon &#45;&#45;tooltip"
  mipTextColor="success"
  icon="location"
  (click)="showTooltip(iconCheckinLocal, $event)"
  [bold]="true"
  [size]="eventIconsSize"
  matTooltip="GotChosen Experience - Check-in Presencial"
></mip-icon>

<mip-icon
  #iconCheckin="matTooltip"
  *ngIf="user && user.hasCheckedInEvent('GOTCHOSEN_EXPERIENCE') && !user.hasCheckedInEvent('GOTCHOSEN_EXPERIENCE', true)"
  class="__badge-icon &#45;&#45;tooltip"
  mipTextColor="success"
  icon="globe"
  (click)="showTooltip(iconCheckin, $event)"
  [bold]="true"
  [size]="eventIconsSize"
  matTooltip="GotChosen Experience - Check-in"
></mip-icon>-->
