import {
  CollabAmbassadorApprovalVideoInterface,
  CollabAmbassadorAudienceInterface,
  CollabAmbassadorCategoriesInterface,
  CollabAmbassadorContactsInterface,
  CollabAmbassadorKeyMetricsInterface,
  CollabAmbassadorResumeInterface,
  CollabAmbassadorStatusInterface,
  CollabCampaignModel,
  PostModel,
  UserModel
} from '@api/models/';
import { CollabCampaignsPaginationInterface } from '@collab/collab.service';
import { ModelFactoryService } from '@services/model-fectory/model-factory.service';
import { ResourceService } from '@services/resource/resource.service';
import { CollabAmbassadorInterface } from './';

export class CollabAmbassadorModel extends UserModel implements CollabAmbassadorInterface {

  contests: CollabCampaignModel[] = null;

  type: 'creator' | 'potential_creator' = null;

  ambassador = null;
  brandCampaignType = null;
  brandCampaignStatus = null;

  instagramUsername: string = null;

  posts: PostModel[] = [];
  reels: PostModel[] = [];

  inviteStatus: CollabAmbassadorStatusInterface = null;

  categories: CollabAmbassadorCategoriesInterface = null;

  approvalVideo: CollabAmbassadorApprovalVideoInterface = null;

  resume: CollabAmbassadorResumeInterface = {
    audience: 0,
    engagement: 0,
    followers: 0,
    views: 0,
    filtered_followers: 0,
    avg_engagement: 0,
    avg_views_per_post: 0,
    avg_views_post: 0,
    interactions: 0,
    posts: 0,
    reach: 0,
  };

  audience: CollabAmbassadorAudienceInterface = {
    country: null,
    state: null,
    locale: null,
    city: null,
    gender: null,
    age: null
  };

  keyMetrics: CollabAmbassadorKeyMetricsInterface = {
    followers: null,
    views: null,
    engagement: null,
    accounts_reached: null,
    interactions: null,
    posts: null,
    avg_views_post: null
  };

  contacts: CollabAmbassadorContactsInterface = {};

  constructor(model?: CollabAmbassadorInterface) {
    // console.trace('collab-ambassador.model->constructor(): model', model);
    super(model);
    this.fill(model);
  }

  override beforeFill(data) {
    try {
      // console.log('collab-ambassador.model->constructor(): data', data);

      const overwriteObjectsName = [
        'resume',
        'audience',
        'keyMetrics',
        'contacts'
      ];

      // preventing errors when the following data comes NULL from the API
      data.resume = data.resume || {};
      data.audience = data.audience || {};
      data.keyMetrics = data.keyMetrics || {};

      // id NULL or "editable: true", ignore the "contacts" info (it means that
      // the user can edit it for now, so we won't show this info on dashboard)
      if (!data.contacts/* || (data.contacts && data.contacts.editable)*/) {
        data.contacts = {};
      }

      const { contacts } = data;

      if (contacts && contacts.id && contacts.address) {
        contacts.fullAddress = `${contacts.address} ${contacts.city}/${contacts.state} - ${contacts.postalCode}`;
      }

      console.log('collab-ambassador.model->beforeFill(): BEFORE data.keyMetrics', data.keyMetrics);

      if (!data.keyMetrics || Object.keys(data.keyMetrics).length === 0) {
        data.keyMetrics = {
          followers: data['instagramFollowers'],
          views: data['instagramViews'],
          engagement: data['instagramEngagement'],
          avg_views_post: data['instagramAvgViewsPost'],
        };
      }

      console.log('collab-ambassador.model->beforeFill(): data.keyMetrics', data.keyMetrics);

      if (!data.audience) {
        data.audience = {};
      }

      if (data['instagramFollowersCities']) {
        data.audience.city = data['instagramFollowersCities'] || [];
      }

      if (data['instagramFollowersStates']) {
        data.audience.state = data['instagramFollowersStates'] || [];
      }

      if (data['instagramFollowersCountries']) {
        data.audience.country = data['instagramFollowersCountries'] || [];
      }

      if (data['instagramFollowersLocations']) {
        data.audience.locale = data['instagramFollowersLocations'] || [];
      }

      if (data['instagramFollowersAges']) {
        data.audience.age = data['instagramFollowersAges'] || [];
      }

      if (data['instagramFollowersGenders']) {
        data.audience.gender = data['instagramFollowersGenders'] || [];
      }

      console.log('collab-ambassador.model->beforeFill(): data.audience', data.audience);

      // to ensure we don't overwrite the following object, lets merge it...
      // let's merge it...
      for (let i in overwriteObjectsName) {

        const name = overwriteObjectsName[i];
        const obj = data[name];
        // console.log('collab-ambassador.model->beforeFill(): name', name, this[name]);

        for (let key in obj) {
          // console.log('collab-ambassador.model->beforeFill(): key|obj', key, obj[key]);

          if (
            obj[key] !== null &&
            obj[key] !== undefined
          ) {
            if (!this[name]) {
              this[name] = {};
            }
            // console.log('collab-ambassador.model->beforeFill(): name|key', name, key, this);
            this[name][key] = obj[key];
          }

          data[name] = this[name];

        }

      }

      // console.log('collab-ambassador.model->beforeFill(): data.categories', data.type, data.categories);

      if (!data.type) {
        data.type = 'creator';
      }

      if (data.categories) {
        if (Array.isArray(data.categories)) {
          if (data.type === 'potential_creator') {
            if (data.categories.length) {

              const categories: CollabAmbassadorCategoriesInterface = {};

              categories.primaryCategory = {
                id: `primary-${data.id}`,
                name: data.categories[0]
              };

              if (data.categories[1]) {
                categories.secondaryCategory = {
                  id: `secondary-${data.id}`,
                  name: data.categories[1]
                };
              }

              data.categories = categories;

            }
          }
          else {
            data.categories = null;
          }
        }
        else if ('primary_category' in data.categories) {

          // console['logger'].log('collab-ambassador.model->beforeFill(): data.categories', data.categories);

          data.categories.primaryCategory = data.categories.primary_category;
          data.categories.secondaryCategory = data.categories.secondary_category;

          delete data.categories.primary_category;
          delete data.categories.secondary_category;

        }
      }

      super.beforeFill(data);

    }
    catch (e) {
      console.error('collab-ambassador.model->beforeFill(): ERROR', e);
    }
  }

  override afterFill(model) {
    // console['logger'].log('collab-ambassador.model->afterFill(): model', model);

    if (model.posts) {
      this.posts = ModelFactoryService.instance.postFactory.buildCollection(model.posts);
    }

    if (model.reels) {

      const reels = [];
      let countReels = 1;

      for (const reel of model.reels) {

        /*createdAt:"2024-02-09 09:56:05+00"
         expiration:"2024-07-30 20:21:16+00:00"
         expirationTimestamp:1722370876
         id:32427085
         media_type:"cover"
         mimeType:"image/jpeg"
         original:"original/image/2024/02/09/65c5f6afb1613603729355/65c5f6b53923f050336440.jpg"
         size:63636
         source:"https://qa-assets.gotchosen.com/post/media/original/image/2024/02/09/65c5f6afb1613603729355/65c5f6b53923f050336440.jpg?Expires=1722370876&Signature=rHucPp4GLfjhOeMPPYauZQWeCqa0AUa-Ickb7bpnmUqebNrhPjK4ErZp-zQunl8M~bMsoBpXzUUZFPYX66x8HYh57IIzsT0JbuR88AJBI4KGCd0UdWcIdLAK1bPTltJrQDUuFEgD3loFdv-IOsIWOWz~wN1z~PnRF8LEwJz-NL5ccYQjBDBlsYwsvmqiSbUq0RM3-m81-qAhrSXd0rWivZzDn9LbIB-maqR~wXm05wZuMfgpEnCnqCaeFMhMG0BpQB9is0j4Z9gpU4SsDuB1Jtcj1LHzKcmIR6aSCh8sAxKlMir2ZLlqPKlN5IXT3TG0LUh2yf84eakVZWdPx26FCQ__&Key-Pair-Id=K22DWSC6J6UX85"
         updatedAt:"2024-02-09 09:56:05+00"*/

        reels.push({
          id: `reels-${model.id}-${countReels}`,
          description: reel.description,
          medias: {
            cover: {
              id: `cover-${model.id}-${countReels}`,
              source: reel.cover,
              mediaType: 'cover',
              mimeType: 'image/jpeg',
            },
            preview: {
              id: `preview-${model.id}-${countReels}`,
              source: reel.cover,
              mediaType: 'preview',
              mimeType: 'image/jpeg',
            },
            high_quality: {
              id: `high_quality-${model.id}-${countReels}`,
              source: reel.source,
              mediaType: 'high_quality',
              mimeType: 'video/mp4',
            }
          }
        });

        countReels++;
      }

      this.reels = ModelFactoryService.instance.postFactory.buildCollection(reels);

      // console.log('collab-ambassador.model->afterFill(): reels', reels);
      // console.log('collab-ambassador.model->afterFill(): this.reels', this.reels);

    }

    super.afterFill(model);

  }

  isInstagramTokenInvalid() {
    return this.roles.indexOf('ROLE_INSTAGRAM_ACCESS_TOKEN_INVALID') > -1;
  }

  isInstagramTokenValid() {
    return this.roles.indexOf('ROLE_INSTAGRAM_ACCESS_TOKEN_VALID') > -1;
  }

  /**
   * Method to return the user posts
   */
  override async getPosts(params?: { limit?: number, lastId?: number, type?: string }): Promise<{ lastId, totalCount, list }> {
    console['logger'].log('collab-ambassador.model->getPosts(): params', params);

    try {

      const response = await ResourceService.instance.get('collabApi', {
        resource: 'collab-posts',
        path: `/users/${this.id}/posts`,
        params
      });
      console['logger'].log('collab-ambassador.model->getPosts(): response', response);

      this.posts = ModelFactoryService.instance.postFactory.buildCollection(response);
      console['logger'].log('collab-ambassador.model->getPosts(): this.posts', this.posts);

      return {
        lastId: null,
        totalCount: null,
        list: this.posts
      };

    }
    catch (e) {
      throw e;
    }

  }

  /**
   * Method collab user audience
   */
  async getAudience(): Promise<CollabAmbassadorInterface['audience']> {
    console.log('collab-ambassador.model->getAudience()');

    try {

      if (this.type === 'potential_creator') {
        console.log('collab-ambassador.model->getAudience(): RETURN', this.audience);
        return this.audience;
      }

      const response = await ResourceService.instance.get('collabApi', {
        resource: 'collab-creators',
        path: `/users/${this.id}/audience`,
        params: { type: this.type }
      });
      console.log('collab-ambassador.model->getAudience(): response', response);

      this.audience = response;
      console.log('collab-ambassador.model->getAudience(): this.audience', this.audience);

      return this.audience;

    }
    catch (e) {
      throw e;
    }

  }

  /**
   * Method collab user key metrics
   */
  async getKeyMetrics(): Promise<CollabAmbassadorInterface['keyMetrics']> {
    console.log('collab-ambassador.model->getKeyMetrics()', this);

    try {

      if (this.type === 'potential_creator') {
        return this.keyMetrics;
      }

      const response = await ResourceService.instance.get('collabApi', {
        resource: 'collab-creators',
        path: `/users/${this.id}/key-metrics`,
        params: { type: this.type }
      });
      console['logger'].log('collab-ambassador.model->getKeyMetrics(): response', response);

      this.keyMetrics = response;
      console['logger'].log('collab-ambassador.model->getKeyMetrics(): this.audience', this.audience);

      return this.keyMetrics;

    }
    catch (e) {
      throw e;
    }

  }

  /**
   * Method collab user campaigns
   */
  async getCampaigns(params: { page?: number, limit: number } = { limit: 30 }, fromCache = true): Promise<CollabCampaignsPaginationInterface> {
    try {
      console['logger'].log('collab-ambassador.model->getCampaigns(): params', params);

      const response = await ResourceService.instance.get('collabApi', {
        resource: 'collab-campaigns',
        path: `/users/${this.id}/campaigns`,
        fromCache,
        params,
        removeHeaders: ['user-country', 'device-uuid']
      });
      console['logger'].log('collab-ambassador.model->getCampaigns(): response', response);

      const items = ModelFactoryService.instance.collabCampaignFactory.buildCollection(response.items);
      console['logger'].log('collab-ambassador.model->getCampaigns(): items', items);

      return {
        items: items,
        totalItems: response.totalItems,
        page: response.page,
        limit: response.limit
      };

    }
    catch (e) {
      console.error('collab-ambassador.model->getCampaigns(): ERROR', e);
      throw e;
    }
  }

  /**
   * Method collab remove remove deliverable
   */
  async removeDeliverable(postId) {
    try {

      const response = await ResourceService.instance.delete('collabApi', {
        resource: 'collab-creators',
        path: `/deliverable/${postId}`,
        removeHeaders: ['user-country', 'device-uuid']
      });
      console['logger'].log('collab-ambassador.model->getCampaigns(): response', response);

    }
    catch (e) {
      console.error('collab-ambassador.model->removeDeliverable(): ERROR', e);
      throw e;
    }
  }

  /**
   * Method collab approve creator video
   */
  async approveVideo(campaignId: string) {
    try {

      // console.log('collab-ambassador.model->approveVideo(): campaignId', campaignId, this);

      if (!campaignId) {
        throw new Error(`'campaignId' is required!`);
      }

      const response = await ResourceService.instance.put('collabApi', {
        resource: 'collab-creators',
        path: `/campaigns/${campaignId}/video-submission/approve/${this.id}`,
        removeHeaders: ['user-country', 'device-uuid']
      });
      // console.log('collab-ambassador.model->approveVideo(): response', response);

      let adminUser = null;

      if (response.adminUser) {
        adminUser = ModelFactoryService.instance.userFactory.build(response.adminUser);
      }

      this.approvalVideo = { ...response, adminUser };
      // console.log('collab-ambassador.model->approveVideo(): this.approvalVideo', this.approvalVideo);

    }
    catch (e) {
      console.error('collab-ambassador.model->approveVideo(): ERROR', e);
      throw e;
    }
  }

  /**
   * Method collab reject creator video
   */
  async rejectVideo(campaignId: string, payload: { reason: string }) {
    try {

      // console.log('collab-ambassador.model->rejectVideo(): campaignId', campaignId);

      if (!campaignId) {
        throw new Error(`'campaignId' is required!`);
      }

      if (!payload || !payload.reason) {
        throw new Error(`'reason' is required!`);
      }

      const response = await ResourceService.instance.put('collabApi', {
        resource: 'collab-creators',
        path: `/campaigns/${campaignId}/video-submission/reject/${this.id}`,
        data: payload,
        removeHeaders: ['user-country', 'device-uuid']
      });
      // console.log('collab-ambassador.model->rejectVideo(): response', response);

      let adminUser = null;

      if (response.adminUser) {
        adminUser = ModelFactoryService.instance.userFactory.build(response.adminUser);
      }

      this.approvalVideo = { ...response, adminUser };
      // console.log('collab-ambassador.model->rejectVideo(): this.approvalVideo', this.approvalVideo);

    }
    catch (e) {
      console.error('collab-ambassador.model->rejectVideo(): ERROR', e);
      throw e;
    }
  }

}
