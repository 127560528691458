<!-- ROUTE or USERNAME -->
<a
  class="profile-slug-name --profile"
  *ngIf="enableProfileLink && (route || user)"
  routerLink="{{ route ? route : '/@' + user?.username }}"
  [target]="openProfileLinkNewPage ? '_blank' : ''"
>
  <ng-container *ngTemplateOutlet="profileSlugNameTpl"></ng-container>
</a>

<!-- LINK -->
<a
  class="profile-slug-name --link"
  *ngIf="link"
  [href]="link"
  [target]="openProfileLinkNewPage ? '_blank' : ''"
>
  <ng-container *ngTemplateOutlet="profileSlugNameTpl"></ng-container>
</a>

<!-- STATIC -->
<span
  class="profile-slug-name --static"
  *ngIf="!enableProfileLink && !route && !link && (user || profileSlugName)"
>
  <ng-container *ngTemplateOutlet="profileSlugNameTpl"></ng-container>
</span>

<ng-template #profileSlugNameTpl>

  <!-- AVATAR -->
  <span
    class="profile-avatar"
    *ngIf="showAvatar || avatarImg || avatarIcon"
    [style.min-width]="avatarSize + 'px'"
    [class.--theme-light]="theme === 'light'"
  >
      <mip-avatar
        *ngIf="showAvatar || avatarImg"
        [userModel]="user"
        [size]="avatarSize"
        [source]="avatarImg"
        [borderRadius]="avatarBorderRadius"
        [elevation]="avatarElevation"
      >
    </mip-avatar>
    <mip-icon
      *ngIf="avatarIcon"
      [icon]="avatarIcon"
      size="2x"
      [mipTextColor]="avatarIconColor"
    ></mip-icon>
  </span>

  <!-- NAME -->
  <span
    class="__name"
    *ngIf="name || (showName && user?.getName())"
    [class.--theme-light]="theme === 'light'"
  >

    <span
      class="__name-content"
      [class.--inline]="inlineUsername"
      [class.--horizontal-center]="horizontalCenter"
    >

      <span>
        <span class="__text __user-name" [matTooltip]="name || user?.getName()">{{name || user?.getName()}}</span>
      </span>

      <span class="__username">
        <span class="__at">@</span>
        <span class="__text" [matTooltip]="'@' + profileSlugName">{{profileSlugName}}</span>
        <small class="__role-label" *ngIf="showBadges && user?.isModerator" matTooltip="Moderator">M</small>
        <mip-mip-profile-slug-name-badges
          *ngIf="showBadges && user"
          [user]="user"
          [badgeSize]="badgeSize"
        ></mip-mip-profile-slug-name-badges>

        <button
          class="__copy mip-button--size-xs"
          mat-icon-button
          *ngIf="showCopyIcon"
          matTooltip="Copy"
          (click)="copyValue(profileSlugName); $event.preventDefault(); $event.stopPropagation()"
        >
          <mat-icon class="gc-icon--size-1x">content_copy</mat-icon>
        </button>

      </span>

    </span>

    <small class="__email" *ngIf="user && showEmail">
      <span [matTooltip]="user.email">{{user.email}}</span>

      <button
        class="__copy mip-button--size-xs"
        mat-icon-button
        *ngIf="showCopyIcon"
        matTooltip="Copy"
        (click)="copyValue(user.email); $event.preventDefault(); $event.stopPropagation()"
      >
        <mat-icon class="gc-icon--size-1x">content_copy</mat-icon>
      </button>

    </small>

    <ng-content></ng-content>

  </span>

  <span
    class="__username"
    *ngIf="!name && (!showName || showName && !user?.getName())"
    [class.--theme-light]="theme === 'light'"
  >
    <span class="__username-content">
      <span class="__at">@</span>
      <span class="__text" [matTooltip]="'@' + profileSlugName">{{profileSlugName}}</span>
      <small class="__role-label" *ngIf="showBadges && user?.isModerator" matTooltip="Moderator">M</small>
      <mip-mip-profile-slug-name-badges
        *ngIf="showBadges && user"
        [user]="user"
        [badgeSize]="badgeSize"
      ></mip-mip-profile-slug-name-badges>

      <button
        class="__copy mip-button--size-xs"
        mat-icon-button
        *ngIf="showCopyIcon"
        matTooltip="Copy"
        (click)="copyValue(profileSlugName); $event.preventDefault(); $event.stopPropagation()"
      >
          <mat-icon class="gc-icon--size-1x">content_copy</mat-icon>
        </button>

    </span>

    <small class="__email" *ngIf="user && showEmail">
      <span [matTooltip]="user.email">{{user.email}}</span>

      <button
        class="__copy mip-button--size-xs"
        mat-icon-button
        *ngIf="showCopyIcon"
        matTooltip="Copy"
        (click)="copyValue(user.email); $event.preventDefault(); $event.stopPropagation()"
      >
        <mat-icon class="gc-icon--size-1x">content_copy</mat-icon>
      </button>

    </small>

    <ng-content></ng-content>
  </span>
</ng-template>
